@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 16px 0;
  width: 100%;
  background-color: var(--color-green-600);
  transition-property: background-color, transform;
  transition-duration: 0.25s;

  &.logo {
    @include media(mobile-l) {
      margin-right: 25px;
      max-width: 200px;
    }
  }

  &.hide {
    transform: translateY(-200%);
  }
}
