@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.destinations {
  &__grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  &__title {
    position: relative;
    margin-bottom: 74px;
    color: var(--color-green-600);

    &::before {
      content: "";
      position: absolute;
      bottom: -36px;
      width: 100%;
      height: 1px;
      background-color: currentcolor;
      opacity: 0.2;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  h2 {
    margin-bottom: 13px;
  }

  h3 {
    font-size: 16px;
    opacity: 0.8;
  }

  &.background {
    .destinations__title {
      color: var(--color-white);
    }

    .overlay {
      &::before {
        background: var(--gradient-blue);
      }
    }
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__content {
      order: -1;
    }

    &__title {
      margin-bottom: 36px;

      &::before {
        bottom: -14px;
      }
    }

    h2 {
      text-align: center;
    }
  }

  @include media(mobile-m) {
    ul {
      grid-template-columns: 1fr;
    }
  }
}
