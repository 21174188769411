@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.visa {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 25px;
  }

  &__content {
    width: 100%;
    max-width: 444px;
  }

  &__image {
    margin-bottom: 26px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  h3 {
    margin-bottom: 14px;
  }

  @include media(tablet) {
    &__wrapper {
      grid-template-columns: 1fr;
    }

    &__content {
      max-width: 100%;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
