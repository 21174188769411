@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: 36px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 36px;
    }
  }

  &__link {
    font-weight: var(--fw-500);
    font-size: 18px;
    line-height: 1.5;
    color: var(--color-white);
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-red-400);
    }

    &.true {
      color: var(--color-red-400);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__block {
    span {
      padding-left: 4px;
      font-weight: var(--fw-700);
    }
  }

  &--footer {
    align-items: flex-start;
    margin-bottom: 48px;
  }

  @include media(tablet) {
    &__inner {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.2);
      width: 100%;
      height: var(--vh);
      max-width: 350px;
      background-color: var(--color-green-600);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.25s;
    }

    &__list {
      z-index: -1;
      flex-direction: column;
      margin: 0;
    }

    &__item {
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &__link {
      font-size: 20px;
    }

    &__btns {
      flex-direction: column;
      gap: 24px;
    }
  }

  @include media(mobile-l) {
    &--footer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      gap: 25px;
    }
  }
}
