@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.local {
  &__grid {
    display: grid;
    gap: 24px;
  }

  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  h2 {
    margin-bottom: 36px;
  }

  @include media(tablet) {
    &__item {
      grid-template-columns: 1fr;

      &:last-of-type {
        .local__content {
          order: -1;
        }
      }
    }

    h2 {
      margin-bottom: 24px;
      text-align: center;

    }
  }
}
