@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  padding: rem(88) 0;

  &__inner {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  p {
    margin-bottom: 24px;
    width: 100%;
    max-width: 533px;
    font-weight: var(--fw-500);
    font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
    line-height: 1.38;
    letter-spacing: 0;
    color: var(--color-green-600);
  }

  .btn {
    padding: 10px 26px;
  }

  @include media(tablet-wide) {
    padding: 45px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__inner {
      grid-template-columns: 1fr;
    }

    p {
      max-width: 100%;
    }

    img {
      width: 100%;
      object-position: center;
      aspect-ratio: 2/1;
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
