@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  background-color: var(--color-white);

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 40px;
    padding: 16px;
    min-height: 271px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.3);
      inset: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
  }

  &__desc {
    width: 100%;
    max-width: 357px;
    color: var(--color-white);

    p {
      opacity: 1;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 725px;
    gap: 8px;

    label {
      width: 100%;
    }

    input {
      overflow: hidden;
      border-radius: var(--radius-big);
      padding: 28px 36px;
      width: 100%;
      color: var(--color-gray-300);
      background-color: var(--color-gray-100);

      &::placeholder {
        font-style: italic;
        font-size: 18px;
      }
    }
  }

  h2 {
    margin-bottom: 3px;
    font-family: var(--font-family-primary);
    line-height: 1;
  }

  p {
    font-weight: var(--fw-500);
  }

  .btn {
    border-radius: var(--radius-big);
    padding: 23px;
    height: fit-content;
    min-width: 140px;
  }

  &--main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }

  @include media(tablet) {
    &__content {
      flex-direction: column;
      justify-content: center;
    }

    &__desc {
      max-width: 100%;
      text-align: center;
    }
  }

  @include media(mobile-m) {
    &__form {
      flex-direction: column;

      input {
        padding: 15px;
      }
    }

    .btn {
      padding: 11px;
      max-width: 100%;
    }
  }
}
