@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.mount {
  border-radius: 0;
  min-height: 540px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    background: var(--gradient-blue);
  }

  @include media(tablet) {
    min-height: 400px;
  }

  @include media(mobile-l) {
    min-height: 350px;
  }
}
