@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.exchange {
  padding-bottom: 35px;

  &__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }

  &__content {
    ul {
      margin-bottom: 30px;
    }

    li {
      position: relative;
      padding-left: 25px;
      letter-spacing: 0.01em;
      color: var(--color-green-600);
      opacity: 0.6;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 7px;
        width: 5px;
        height: 5px;
        background-color: currentcolor;
        opacity: 0.6;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    p {
      color: var(--color-green-600);
    }
  }

  &__image {
    margin-bottom: 24px;
    border-radius: 24px 0 0 24px;

    &:last-of-type {
      border-radius: 0 24px 24px 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  h2 {
    margin-bottom: 34px;
  }

  @include media(tablet-wide) {
    h2 {
      margin-bottom: 20px;
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
    }
  }
}
