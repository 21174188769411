@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.tours {
  &__item {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 25px;

    &:first-of-type {
      margin-bottom: 21px;
    }

    &:last-of-type {
      align-items: center;
    }
  }

  &__desc,
  &__content {
    width: 100%;
    max-width: 420px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__content {
    p {
      margin-bottom: 16px;
    }

    li {
      position: relative;
      padding-left: 25px;
      letter-spacing: 0.01em;
      opacity: 0.6;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 7px;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background-color: currentcolor;
        opacity: 0.6;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 21px;
  }

  img {
    height: 100%;
  }

  @include media(tablet) {
    &__item {
      align-items: center;
      justify-content: center;
      grid-template-columns: 1fr;

      &:last-of-type {
        .tours__content {
          order: -1;
        }
      }
    }

    img {
      width: 100%;
      object-position: center;
      aspect-ratio: 2/1;
    }

    h2,
    h3 {
      text-align: center;
    }

    li {
      padding-left: 20px;
    }

    &__desc,
    &__content {
      max-width: 100%;
    }
  }
}
