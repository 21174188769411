@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.policy {
  &__content {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  h1 {
    margin-bottom: 35px;
    font-size: clamp(35px, 1.25rem + 1.3889vi, 40px);
    line-height: 1.3;
    color: var(--color-red-400);
  }

  h2 {
    margin-bottom: 24px;
    font-size: clamp(1.25rem, 1.0417rem + 0.9259vi, 1.875rem);
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  p,
  li {
    color: var(--color-green-600);
    opacity: 1;
  }

  a {
    text-decoration: underline;
    color: currentcolor;
  }
}
