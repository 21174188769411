/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 99px;
  padding: 7px 23px;
  width: 100%;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-600);
  font-size: 18px;
  line-height: 1.5;
  color: var(--color-white);
  background-color: var(--color-red-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-red-400);
    color: var(--color-red-400) !important;
    background-color: var(--color-white);
  }

  &--sign {
    color: var(--color-dark-900);
    background-color: var(--color-white);
  }

  &--big {
    padding: 12px 47px;
  }

  &--slider {
    position: relative;
    z-index: 2;
    border-radius: 50%;
    padding: 0;
    min-width: 36px;
    min-height: 36px;
    background-color: var(--color-white);
    opacity: 0.6;
    transition: opacity 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      mask-image: url("../images/icons/arrow.svg");
      width: 8px;
      height: 12px;
      background-color: var(--color-black);
    }

    &:hover,
    &:focus {
      border-color: transparent;
      opacity: 1;
    }
  }
}
