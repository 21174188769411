@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.guide {
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__content {
    p {
      margin-bottom: 14px;
      opacity: 0.8;
    }

    li {
      position: relative;
      margin-bottom: 14px;
      padding-left: 35px;
      letter-spacing: 0.01em;
      color: var(--color-white);
      opacity: 0.6;

      &::before {
        content: "";
        position: absolute;
        left: 17px;
        top: 5px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: currentcolor;
        opacity: 0.6;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  h2 {
    margin-bottom: 11px;
  }

  li {
    position: relative;
    padding-left: 25px;
    letter-spacing: 0.01em;
    color: var(--color-white);
    opacity: 0.6;

    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      top: 7px;
      left: 10px;
      border-radius: 50%;
      background-color: currentcolor;
      opacity: 0.6;
    }

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  img {
    height: 100%;
  }

  &__desc {
    h2 {
      margin-bottom: 10px;
    }

    + p {
      margin-bottom: 24px;
    }
  }

  &--background {
    h2 {
      margin-bottom: 24px;
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
    }

    &__desc {
      order: -1;
    }
  }
}
