@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.discover {
  &__content {
    margin: 0 auto 42px;
    text-align: center;
    color: var(--color-white);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 867px;
    gap: 20px 24px;
    margin-inline: auto;
  }

  &__item {
    overflow: hidden;
    border-radius: 16px;
    background-color: var(--color-white);
  }

  &__desc {
    padding: 24px;
  }

  h2 {
    margin-bottom: 13px;
  }

  h3 {
    margin-bottom: 11px;
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__desc {
      padding: 16px;
    }
  }
}
