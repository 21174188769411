@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2 {
  overflow: hidden;
  margin-bottom: 24px;
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-700);
  font-size: clamp(30px, 1.1667rem + 3.1481vi, 64px);
  line-height: 1.26;
  letter-spacing: 0.01rem;
}

h2,
.title {
  margin-bottom: 16px;
  font-size: clamp(30px, 1.25rem + 1.3889vi, 40px);
  line-height: 1.4;
}

.title {
  font-size: clamp(1.25rem, 1.0417rem + 0.9259vi, 1.875rem);
}

h3 {
  margin-bottom: 16px;
  font-weight: var(--fw-600);
  font-size: 18px;
  line-height: 1.1;
  color: var(--color-green-600);
  letter-spacing: 0.01rem;
}

p:not([class]) {
  letter-spacing: 0.01em;
  opacity: 0.6;
}

.subtext {
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  opacity: 0.8;
}
