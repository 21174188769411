@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  padding: 40px 0;
  color: var(--color-white);
  background-color: var(--color-green-600);

  a {
    line-height: 1.5;
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-red-400);
    }
  }

  &__list {
    display: flex;
    gap: 27px;

    a {
      font-size: 18px;
    }
  }

  &__copy {
    display: flex;
    justify-content: space-between;

    p {
      opacity: 1;
    }
  }

  &__policy {
    display: flex;
    gap: 24px;
  }

  .logo {
    margin-bottom: 14px;
  }

  @include media(mobile-l) {
    padding: 30px 0;

    &__copy {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 25px;
    }

    &__policy {
      justify-content: center;
    }
  }
}
