@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.welcome {
  padding-bottom: rem(81);

  &__content {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    margin-bottom: 24px;
    gap: 25px;
  }

  &__desc {
    width: 100%;
    max-width: 421px;
  }

  &__grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    gap: 25px;
  }

  &__description {
    h2 {
      margin-bottom: 22px;
      font-size: clamp(1.25rem, 1.0417rem + 0.9259vi, 1.875rem);
      line-height: 1.33;
    }

    h3 {
      margin-bottom: 3px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 644px;
    gap: 25px;
  }

  h2 {
    margin-bottom: 24px;
  }

  @include media(tablet) {
    padding: 40px;

    &__content {
      grid-template-columns: 1fr;
    }

    &__desc {
      order: -1;
      max-width: 100%;
      text-align: center;
    }

    &__grid {
      justify-content: center;
      grid-template-columns: 1fr;
    }

    &__list {
      max-width: 100%;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
