@import "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Merriweather", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #000;
  --color-dark-900: #252b42;
  --color-gray-100: #eee;
  --color-gray-300: #ababab;
  --color-green-600: #074760;
  --color-red-400: #d91023;
  --radius-main: 10px;
  --radius-big: 24px;
  --gradient-white: linear-gradient(180deg, #cecfd000 77.059%, #fffc 100%), #eeeeee14;
  --gradient-blue: linear-gradient(180deg, #07476000 53.69%, #074760 104.889%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  letter-spacing: 0;
  color: var(--color-dark-900);
  background-color: var(--color-gray-100);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.25;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section, .background {
  padding: 3.75rem 0;
}

@media only screen and (max-width: 1180px) {
  .section, .background {
    padding: 45px 0;
  }
}

@media only screen and (max-width: 992px) {
  .section, .background {
    padding: 30px 0;
  }
}

.background {
  color: var(--color-white);
  background-color: var(--color-green-600);
}

.background h2, .background h3 {
  color: var(--color-white);
}

.background .discover__desc h3 {
  color: var(--color-green-600);
}

.background .discover__desc p {
  color: var(--color-dark-900);
}

.background .overlay:before {
  background: var(--gradient-blue);
}

.container, .wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 1122px;
}

.overlay {
  z-index: 1;
  border-radius: var(--radius-big);
  position: relative;
  overflow: hidden;
}

.overlay:before {
  content: "";
  background: linear-gradient(#4f90b600 76.405%, #eeec 100%);
  position: absolute;
  inset: 0;
}

.overlay--white {
  border-radius: 16px;
}

.overlay--white:before {
  background: var(--gradient-white);
}

@media only screen and (max-width: 992px) {
  .overlay img {
    width: 100%;
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 300px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-700);
  letter-spacing: .01rem;
  margin-bottom: 24px;
  font-size: clamp(30px, 1.1667rem + 3.1481vi, 64px);
  line-height: 1.26;
  overflow: hidden;
}

h2, .title {
  margin-bottom: 16px;
  font-size: clamp(30px, 1.25rem + 1.3889vi, 40px);
  line-height: 1.4;
}

.title {
  font-size: clamp(1.25rem, 1.0417rem + .9259vi, 1.875rem);
}

h3 {
  font-weight: var(--fw-600);
  color: var(--color-green-600);
  letter-spacing: .01rem;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.1;
}

p:not([class]) {
  letter-spacing: .01em;
  opacity: .6;
}

.subtext {
  font-weight: var(--fw-500);
  letter-spacing: .01em;
  opacity: .8;
  font-size: 18px;
  line-height: 1.1;
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-green-600);
  padding: 16px 0;
  transition-property: background-color, transform;
  transition-duration: .25s;
  position: fixed;
  top: 0;
}

@media only screen and (max-width: 768px) {
  .header.logo {
    max-width: 200px;
    margin-right: 25px;
  }
}

.header.hide {
  transform: translateY(-200%);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: 36px;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 36px;
}

.nav__link {
  font-weight: var(--fw-500);
  color: var(--color-white);
  font-size: 18px;
  line-height: 1.5;
  transition: color .3s linear;
}

.nav__link:hover, .nav__link.true {
  color: var(--color-red-400);
}

.nav__btns {
  align-items: center;
  gap: 18px;
  display: flex;
}

.nav__block span {
  font-weight: var(--fw-700);
  padding-left: 4px;
}

.nav--footer {
  align-items: flex-start;
  margin-bottom: 48px;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.2);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-green-600);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .25s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__link {
    font-size: 20px;
  }

  .nav__btns {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .nav--footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-bottom: 25px;
  }
}

[class].btn {
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-600);
  color: var(--color-white);
  background-color: var(--color-red-400);
  border: 1px solid #0000;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 7px 23px;
  font-size: 18px;
  line-height: 1.5;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-red-400);
  background-color: var(--color-white);
  color: var(--color-red-400) !important;
}

[class].btn--sign {
  color: var(--color-dark-900);
  background-color: var(--color-white);
}

[class].btn--big {
  padding: 12px 47px;
}

[class].btn--slider {
  z-index: 2;
  min-width: 36px;
  min-height: 36px;
  background-color: var(--color-white);
  opacity: .6;
  border-radius: 50%;
  padding: 0;
  transition: opacity .3s linear;
  position: relative;
}

[class].btn--slider:before {
  content: "";
  width: 8px;
  height: 12px;
  background-color: var(--color-black);
  position: absolute;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
}

[class].btn--slider:hover, [class].btn--slider:focus {
  opacity: 1;
  border-color: #0000;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  color: var(--color-red-400);
  margin-bottom: 35px;
  font-size: clamp(35px, 1.25rem + 1.3889vi, 40px);
  line-height: 1.3;
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(1.25rem, 1.0417rem + .9259vi, 1.875rem);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy p, .policy li {
  color: var(--color-green-600);
  opacity: 1;
}

.policy a {
  color: currentColor;
  text-decoration: underline;
}

.footer {
  color: var(--color-white);
  background-color: var(--color-green-600);
  padding: 40px 0;
}

.footer a {
  line-height: 1.5;
  transition: color .3s linear;
}

.footer a:hover {
  color: var(--color-red-400);
}

.footer__list {
  gap: 27px;
  display: flex;
}

.footer__list a {
  font-size: 18px;
}

.footer__copy {
  justify-content: space-between;
  display: flex;
}

.footer__copy p {
  opacity: 1;
}

.footer__policy {
  gap: 24px;
  display: flex;
}

.footer .logo {
  margin-bottom: 14px;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }

  .footer__copy {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
  }

  .footer__policy {
    justify-content: center;
  }
}

.contacts {
  background-color: var(--color-white);
}

.contacts__inner {
  min-height: 271px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contacts__inner:before {
  content: "";
  z-index: 1;
  background: none, #ffffff4d;
  position: absolute;
  inset: 0;
}

.contacts__content {
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.contacts__desc {
  width: 100%;
  max-width: 357px;
  color: var(--color-white);
}

.contacts__desc p {
  opacity: 1;
}

.contacts__form {
  width: 100%;
  max-width: 725px;
  align-items: center;
  gap: 8px;
  display: flex;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  border-radius: var(--radius-big);
  width: 100%;
  color: var(--color-gray-300);
  background-color: var(--color-gray-100);
  padding: 28px 36px;
  overflow: hidden;
}

.contacts__form input::placeholder {
  font-size: 18px;
  font-style: italic;
}

.contacts h2 {
  font-family: var(--font-family-primary);
  margin-bottom: 3px;
  line-height: 1;
}

.contacts p {
  font-weight: var(--fw-500);
}

.contacts .btn {
  border-radius: var(--radius-big);
  height: -moz-fit-content;
  height: fit-content;
  min-width: 140px;
  padding: 23px;
}

.contacts--main {
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .contacts__content {
    flex-direction: column;
    justify-content: center;
  }

  .contacts__desc {
    max-width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .contacts__form {
    flex-direction: column;
  }

  .contacts__form input {
    padding: 15px;
  }

  .contacts .btn {
    max-width: 100%;
    padding: 11px;
  }
}

.hero {
  padding: 5.5rem 0;
}

.hero__inner {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 24px;
  display: grid;
}

.hero p {
  width: 100%;
  max-width: 533px;
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-green-600);
  margin-bottom: 24px;
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
  line-height: 1.38;
}

.hero .btn {
  padding: 10px 26px;
}

@media only screen and (max-width: 1180px) {
  .hero {
    padding: 45px;
  }
}

@media only screen and (max-width: 992px) {
  .hero {
    padding: 30px 0;
  }

  .hero__inner {
    grid-template-columns: 1fr;
  }

  .hero p {
    max-width: 100%;
  }

  .hero img {
    width: 100%;
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 576px) {
  .hero .btn {
    max-width: 100%;
  }
}

.slider {
  position: relative;
  overflow: hidden;
}

.slider__inner {
  gap: 25px;
  display: flex;
}

.slider__swiper {
  overflow: hidden;
}

.slider__content {
  width: 100%;
  max-width: 421px;
  color: var(--color-white);
}

.slider__content p {
  letter-spacing: .02em;
  margin-bottom: 2.75rem;
  font-size: 18px;
  line-height: 1.1;
}

.slider__item {
  color: var(--color-white);
}

.slider__item p {
  opacity: .8;
}

.slider__item p:first-of-type {
  margin-bottom: 16px;
}

.slider img {
  width: 100%;
  margin-bottom: 20px;
}

.slider h3 {
  color: var(--color-white);
  margin-bottom: 9px;
  font-size: 18px;
  line-height: 1.4;
}

.slider .btn--slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider .btn--prev {
  left: 24px;
  scale: -1 1;
}

.slider .btn--next {
  left: auto;
  right: 24px;
}

@media only screen and (max-width: 992px) {
  .slider .btn--prev {
    left: 0;
  }

  .slider .btn--next {
    right: 0;
  }
}

.slider .overlay {
  border-radius: 10px;
}

@media only screen and (max-width: 1280px) {
  .slider .btn--slider {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 992px) {
  .slider__inner {
    flex-direction: column;
  }

  .slider__content {
    max-width: 100%;
    text-align: center;
    align-self: center;
  }

  .slider__content .btn {
    margin-inline: auto;
  }

  .slider img {
    aspect-ratio: 1 / 1;
  }
}

@media only screen and (max-width: 768px) {
  .slider img {
    object-position: center;
    aspect-ratio: 16 / 9;
  }

  .slider .btn--slider {
    transform: translateY(50%);
  }
}

.tours__item {
  grid-template-columns: repeat(2, auto);
  gap: 25px;
  display: grid;
}

.tours__item:first-of-type {
  margin-bottom: 21px;
}

.tours__item:last-of-type {
  align-items: center;
}

.tours__desc, .tours__content {
  width: 100%;
  max-width: 420px;
}

.tours__title {
  margin-bottom: 20px;
}

.tours__content p {
  margin-bottom: 16px;
}

.tours__content li {
  letter-spacing: .01em;
  opacity: .6;
  padding-left: 25px;
  position: relative;
}

.tours__content li:before {
  content: "";
  width: 5px;
  height: 5px;
  opacity: .6;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
}

.tours__content li:not(:last-child) {
  margin-bottom: 8px;
}

.tours h2 {
  margin-bottom: 20px;
}

.tours h3 {
  margin-bottom: 21px;
}

.tours img {
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .tours__item {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .tours__item:last-of-type .tours__content {
    order: -1;
  }

  .tours img {
    width: 100%;
    object-position: center;
    aspect-ratio: 2 / 1;
  }

  .tours h2, .tours h3 {
    text-align: center;
  }

  .tours li {
    padding-left: 20px;
  }

  .tours__desc, .tours__content {
    max-width: 100%;
  }
}

.destinations__grid {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 25px;
  display: grid;
}

.destinations__title {
  color: var(--color-green-600);
  margin-bottom: 74px;
  position: relative;
}

.destinations__title:before {
  content: "";
  width: 100%;
  height: 1px;
  opacity: .2;
  background-color: currentColor;
  position: absolute;
  bottom: -36px;
}

.destinations ul {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.destinations h2 {
  margin-bottom: 13px;
}

.destinations h3 {
  opacity: .8;
  font-size: 16px;
}

.destinations.background .destinations__title {
  color: var(--color-white);
}

.destinations.background .overlay:before {
  background: var(--gradient-blue);
}

@media only screen and (max-width: 992px) {
  .destinations__grid {
    grid-template-columns: 1fr;
  }

  .destinations__content {
    order: -1;
  }

  .destinations__title {
    margin-bottom: 36px;
  }

  .destinations__title:before {
    bottom: -14px;
  }

  .destinations h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .destinations ul {
    grid-template-columns: 1fr;
  }
}

.mount {
  min-height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
}

.mount:before {
  background: var(--gradient-blue);
}

@media only screen and (max-width: 992px) {
  .mount {
    min-height: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .mount {
    min-height: 350px;
  }
}

.welcome {
  padding-bottom: 5.0625rem;
}

.welcome__content {
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 25px;
  margin-bottom: 24px;
  display: grid;
}

.welcome__desc {
  width: 100%;
  max-width: 421px;
}

.welcome__grid {
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 25px;
  display: grid;
}

.welcome__description h2 {
  margin-bottom: 22px;
  font-size: clamp(1.25rem, 1.0417rem + .9259vi, 1.875rem);
  line-height: 1.33;
}

.welcome__description h3 {
  margin-bottom: 3px;
}

.welcome__list {
  width: 100%;
  max-width: 644px;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  display: grid;
}

.welcome h2 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .welcome {
    padding: 40px;
  }

  .welcome__content {
    grid-template-columns: 1fr;
  }

  .welcome__desc {
    max-width: 100%;
    text-align: center;
    order: -1;
  }

  .welcome__grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .welcome__list {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .welcome__list {
    grid-template-columns: 1fr;
  }
}

.discover__content {
  text-align: center;
  color: var(--color-white);
  margin: 0 auto 42px;
}

.discover__list {
  width: 100%;
  max-width: 867px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 24px;
  margin-inline: auto;
  display: grid;
}

.discover__item {
  background-color: var(--color-white);
  border-radius: 16px;
  overflow: hidden;
}

.discover__desc {
  padding: 24px;
}

.discover h2 {
  margin-bottom: 13px;
}

.discover h3 {
  margin-bottom: 11px;
}

@media only screen and (max-width: 768px) {
  .discover__list {
    grid-template-columns: 1fr;
  }

  .discover__desc {
    padding: 16px;
  }
}

.visa__wrapper {
  grid-template-columns: repeat(2, auto);
  gap: 25px;
  display: grid;
}

.visa__content {
  width: 100%;
  max-width: 444px;
}

.visa__image {
  margin-bottom: 26px;
}

.visa__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.visa h3 {
  margin-bottom: 14px;
}

@media only screen and (max-width: 992px) {
  .visa__wrapper {
    grid-template-columns: 1fr;
  }

  .visa__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .visa__list {
    grid-template-columns: 1fr;
  }
}

.exchange {
  padding-bottom: 35px;
}

.exchange__inner {
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  display: grid;
}

.exchange__content ul {
  margin-bottom: 30px;
}

.exchange__content li {
  letter-spacing: .01em;
  color: var(--color-green-600);
  opacity: .6;
  padding-left: 25px;
  position: relative;
}

.exchange__content li:before {
  content: "";
  width: 5px;
  height: 5px;
  opacity: .6;
  background-color: currentColor;
  position: absolute;
  top: 7px;
  left: 10px;
}

.exchange__content li:not(:last-child) {
  margin-bottom: 12px;
}

.exchange__content p {
  color: var(--color-green-600);
}

.exchange__image {
  border-radius: 24px 0 0 24px;
  margin-bottom: 24px;
}

.exchange__image:last-of-type {
  border-radius: 0 24px 24px 0;
}

.exchange__image img {
  width: 100%;
  height: 100%;
}

.exchange__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.exchange h2 {
  margin-bottom: 34px;
}

@media only screen and (max-width: 1180px) {
  .exchange h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .exchange__inner {
    grid-template-columns: 1fr;
  }
}

.guide__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  display: grid;
}

.guide__title {
  margin-bottom: 24px;
}

.guide__content p {
  opacity: .8;
  margin-bottom: 14px;
}

.guide__content li {
  letter-spacing: .01em;
  color: var(--color-white);
  opacity: .6;
  margin-bottom: 14px;
  padding-left: 35px;
  position: relative;
}

.guide__content li:before {
  content: "";
  width: 4px;
  height: 4px;
  opacity: .6;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 17px;
}

.guide__content:not(:last-of-type) {
  margin-bottom: 20px;
}

.guide h2 {
  margin-bottom: 11px;
}

.guide li {
  letter-spacing: .01em;
  color: var(--color-white);
  opacity: .6;
  padding-left: 25px;
  position: relative;
}

.guide li:before {
  content: "";
  width: 5px;
  height: 5px;
  opacity: .6;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
}

.guide li:not(:last-child) {
  margin-bottom: 7px;
}

.guide img {
  height: 100%;
}

.guide__desc h2 {
  margin-bottom: 10px;
}

.guide__desc + p, .guide--background h2 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .guide__inner {
    grid-template-columns: 1fr;
  }

  .guide__desc {
    order: -1;
  }
}

.local__grid {
  gap: 24px;
  display: grid;
}

.local__item {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 25px;
  display: grid;
}

.local h2 {
  margin-bottom: 36px;
}

@media only screen and (max-width: 992px) {
  .local__item {
    grid-template-columns: 1fr;
  }

  .local__item:last-of-type .local__content {
    order: -1;
  }

  .local h2 {
    text-align: center;
    margin-bottom: 24px;
  }
}

/*# sourceMappingURL=main.css.map */
