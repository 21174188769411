/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Merriweather", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  //
  --color-white: #fff;
  --color-black: #000;
  --color-dark-900: #252b42;
  --color-gray-100: #eeeeee;
  --color-gray-300: #ababab; --color-green-600: #074760;
  --color-red-400: #d91023;

  //  radius
  --radius-main: 10px;
  --radius-big: 24px;

  --gradient-white: linear-gradient(180deg, rgba(206, 207, 208, 0) 77.059%, rgba(255, 255, 255, 0.8) 100%),
    rgba(238, 238, 238, 0.08);
    --gradient-blue: linear-gradient(180.00deg, rgba(7, 71, 96, 0) 53.69%,rgb(7, 71, 96) 104.889%);
}
