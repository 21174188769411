@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.slider {
  position: relative;
  overflow: hidden;

  &__inner {
    display: flex;
    gap: 25px;
  }

  &__swiper {
    overflow: hidden;
  }

  &__content {
    width: 100%;
    max-width: 421px;
    color: var(--color-white);

    p {
      margin-bottom: rem(44);
      font-size: 18px;
      line-height: 1.1;
      letter-spacing: 0.02em;
    }
  }

  &__item {
    color: var(--color-white);

    p {
      opacity: 0.8;

      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  img {
    margin-bottom: 20px;
    width: 100%;
  }

  h3 {
    margin-bottom: 9px;
    font-size: 18px;
    line-height: 1.4;
    color: var(--color-white);
  }

  .btn {
    &--slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &--prev {
      left: 24px;
      scale: -1 1;
    }

    &--next {
      left: auto;
      right: 24px;
    }

    @include media(tablet) {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }

  .overlay {
    border-radius: 10px;
  }

  @include media(desktop) {
    .btn {
      &--slider {
        transform: translateY(0);
      }
    }
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column;
    }

    &__content {
      align-self: center;
      max-width: 100%;
      text-align: center;

      .btn {
        margin-inline: auto;
      }
    }

    img {
      aspect-ratio: 1/1;
    }
  }

  @include media(mobile-l) {
    img {
      object-position: center;
      aspect-ratio: 16/9;
    }

    .btn {
      &--slider {
        transform: translateY(50%);
      }
    }
  }
}
